body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
  font-family: "Pixeboy";
  src: local("Pixeboy"), url(./Fonts/Pixeboy.ttf) format("truetype");
}
@font-face {
  font-family: "Poly";
  src: local("Poly"), url(./Fonts/Poly-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  src: local("Prompt"), url(./Fonts/Prompt.ttf) format("truetype");
}

@font-face {
  font-family: "Puritan";
  src: local("Puritan"), url(./Fonts/Puritan.ttf) format("truetype");
}
.icon-link:hover {
  color: "#FCE78A" !important;
}
